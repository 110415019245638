<template>
    <div class="receipt-fee-print" v-if="content">
        <div class="header">
            <div class="title">
                <div class="name">
                    บริษัท เยลโลไทร์ จํากัด
                    <br />
                    Yellowtire Co., Ltd.
                </div>
                <div class="address">
                    1818 อาคารรสา ทู ชั้นที่ 18 ถนนเพชรบุรี
                    <br />
                    แขวงมักกะสัน เขตราชเทวี กรุงเทพมหานคร 10400
                    <br />
                    เลขประจำตัวผู้เสียภาษี 0105564107895
                </div>
            </div>

            <div class="original">
                ต้นฉบับ / ORIGINAL
                <br />
                สำหรับลูกค้า
            </div>
        </div>

        <h1>
            ใบเสร็จรับเงิน
            <br />
            RECEIPT
        </h1>

        <div class="info">
            <div class="left">
                <h3>ข้อมูลผู้ซื้อ</h3>
                <div class="data">
                    <template v-if="content.customer.requireTax">
                        {{ content.customer.taxName }} โทร. {{ content.customer.tel }}
                        <br />
                        ที่อยู่ {{ content.customer.taxAddress }}
                        <br />
                        {{ content.customer.taxSubDistrict }} {{ content.customer.taxDistrict }} {{ content.customer.taxProvince }} {{ content.customer.taxPostCode }}
                        <br />
                        เลขประจำตัวผู้เสียภาษี {{ content.customer.taxId }}
                    </template>
                    <template v-else>
                        {{ content.customer.firstName }} {{ content.customer.lastName }} โทร. {{ content.customer.tel }}
                        <template v-if="content.customer.address">
                            <br />
                            {{ content.customer.address }}
                            <br />
                            {{ content.customer.subDistrict }} {{ content.customer.district }} {{ content.customer.province }} {{ content.customer.postCode }}
                        </template>
                    </template>
                </div>
            </div>
            <div class="right">
                <div class="data">
                    <strong>เลขที่</strong> {{ content.receiptFeeNumber }}
                    <br />
                    <strong>วันที่</strong> {{ content.orderDate }}
                </div>
            </div>
        </div>

        <table class="mt-3">
            <thead>
                <tr>
                    <th class="text-center">ลำดับ</th>
                    <th>รายการ</th>
                    <th class="text-center">ใบรับเงินทดรอง</th>
                    <th class="text-center">วันที่ใบรับเงินทดรอง</th>
                    <th class="text-center no-border-right">จำนวนเงิน</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">1</td>
                    <td>ค่าธรรมเนียมอื่นๆ</td>
                    <td class="text-center">{{ content.receiptNumber }}</td>
                    <td class="text-center">{{ displayDateOnly(content.orderDateTime) }}</td>
                    <td class="text-end no-border-right">{{ content.installmentFee }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td>หมายเลขคำสั่งซื้อ {{ content.orderNumber }}</td>
                    <td></td>
                    <td></td>
                    <td class="text-end no-border-right"></td>
                </tr>
                <tr>
                    <td></td>
                    <td>{{ content.customer.licenseNumber }} {{ content.customer.licenseProvince }} / {{ content.customer.carBrand }} {{ content.customer.carModel }}</td>
                    <td></td>
                    <td></td>
                    <td class="no-border-right"></td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td class="no-border-right">&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td class="no-border-right">&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td class="no-border-right">&nbsp;</td>
                </tr>
                <tr>
                    <td>ตัวอักษร</td>
                    <td>({{ arabicNumberToText(content.installmentFee) }})</td>
                    <td></td>
                    <td>รวมเงิน</td>
                    <td class="text-end no-border-right">{{ content.installmentFee }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>ภาษีมูลค่าเพิ่ม 7%</td>
                    <td class="text-end no-border-right">-</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>รวมทั้งหมด</td>
                    <td class="text-end no-border-right">{{ content.installmentFee }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>หัก ณ ที่จ่าย</td>
                    <td class="text-end no-border-right">-</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>รวมเงิน</td>
                    <td class="text-end no-border-right">{{ content.installmentFee }}</td>
                </tr>
            </tbody>
        </table>

        <div class="signatures">
            <div class="item">
                <div class="box flex">
                    <div class="signature">
                        <div class="line"><label>ผู้รับเงิน</label> <div class="signature-line">&nbsp;</div></div>
                        <div class="line"><label>ลงวันที่</label> <div class="signature-line">&nbsp;</div></div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="box flex">
                    <div class="signature">
                        <div class="line"><label>ผู้รับใบเสร็จ</label> <div class="signature-line">&nbsp;</div></div>
                        <div class="line"><label>ลงวันที่</label> <div class="signature-line">&nbsp;</div></div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="box">
                    <span>ในนาม บริษัท เยลโลไทร์ จำกัด</span>
                    <div class="signature-img">
                        <img src="@/assets/receipt-signature.png" />
                        <div class="signature-line"></div>
                    </div>
                    <span>ผู้มีอำนาจลงนาม</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Helper from '@/services/helper';

export default {
    data() {
        return {
            helper: Helper
        }
    },
    props: {
        content: Object
    },
    methods: {
        displayDateOnly(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        arabicNumberToText(number) {
            if (number) {
                number = number.replace(/,/g, '');
                number = this.checkNumber(number);
                const numberArray = new Array ('ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า', 'สิบ');
                const digitArray = new Array ('', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน');
                let bahtText = '';

                if (isNaN(number)) {
                    return '';
                } else {
                    if ((number - 0) > 9999999.9999) {
                        return '';
                    } else {
                        number = number.split ('.');

                        if (number[1].length > 0) {
                            number[1] = number[1].substring(0, 2);
                        }
                        
                        const numberLength = number[0].length - 0;

                        for(let i = 0; i < numberLength; i++) {
                            const tmp = number[0].substring(i, i + 1) - 0;

                            if (tmp != 0) {
                                if ((i == (numberLength - 1)) && (tmp == 1))
                                {
                                    bahtText  += 'เอ็ด';
                                } else if ((i == (numberLength - 2)) && (tmp == 2))
                                {
                                    bahtText  += 'ยี่';
                                } else if ((i == (numberLength - 2)) && (tmp == 1))
                                {
                                    bahtText  += '';
                                } else {
                                    bahtText  += numberArray[tmp];
                                }

                                bahtText  += digitArray[numberLength - i - 1];
                            }
                        }

                        bahtText  += 'บาท';

                        if ((number[1] == '0') || (number[1] == '00')) {
                            bahtText  += 'ถ้วน';
                        } else {
                            const decimalLength = number[1].length - 0;

                            for (let i = 0; i < decimalLength; i++) {
                                const tmp = number[1].substring(i, i + 1) - 0;

                                if (tmp != 0) {
                                    if ((i == (decimalLength - 1)) && (tmp == 1)) {
                                        bahtText  += 'เอ็ด';
                                    } else if ((i == (decimalLength - 2)) && (tmp == 2)) {
                                        bahtText  += 'ยี่';
                                    } else if ((i == (decimalLength - 2)) && (tmp == 1)) {
                                        bahtText  += '';
                                    } else {
                                        bahtText  += numberArray[tmp];
                                    }

                                    bahtText  += digitArray[decimalLength - i - 1];
                                }
                            }

                            bahtText  += 'สตางค์';
                        }

                        return bahtText ;
                    }
                }
            }
        },
        checkNumber(number) {
            let decimal = false;
            number = number.toString();						
            number = number.replace (/ |,|บาท|฿/gi,'');

            for (var i = 0; i < number.length; i++)
            {
                if(number[i] =='.'){
                    decimal = true;
                }
            }
            if (decimal == false){
                number = number+'.00';
            }

            return number
        }
    }
}
</script>

<style lang='scss' scoped>
@import '@/theme/Variable.scss';

.receipt-fee-print {
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    width: 21cm;
    height: 29.7cm;
    padding: 1cm;

    * {
        font-family: 'Tahoma';
        font-size: 12px;
        line-height: 16px;
    }

    .header {
        display: flex;

        .title {
            display: flex;
            flex: 1;

            .name {
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
            }

            .address {
                padding-left: 30px;
            }
        }

        .original {
            width: 170px;
            text-align: right;
        }
    }

    h1 {
        margin: 30px 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .info {
        display: flex;
        justify-content: space-between;

        h3 {
            font-weight: bold;
            margin: 15px 0 10px;
        }

        .left {
            padding-right: 15px;
        }

        .right {
            padding-left: 15px;
            text-align: right;
        }
    }

    table {
        width: 100%;
        border: 1px solid #999;
        border-bottom: none;

        th, td {
            padding: 4px 10px 6px;
            vertical-align: top;
            border-right: 1px solid #999;
            border-bottom: 1px solid #999;

            &.no-border-right {
                border-right: none;
            }
        }

        thead {
            th {
                color: #555;
                white-space: nowrap;
            }
        }
    }

    .signatures {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .item {
            width: 33%;

            .box {
                width: 75%;
                height: 86px;
                margin: auto;
                text-align: center;
                border: 1px solid #999;
                padding: 5px;

                &.flex {
                    display: flex;
                    align-items: center;
                }

                .signature {
                    width: 100%;
                    padding-right: 10px;

                    .line {
                        display: flex;

                        label {
                            width: 60px;
                        }

                        .signature-line {
                            width: auto;
                            flex: 1;
                        }
                    }
                }

                span {
                    display: block;
                }
            }

            .signature-line {
                border-bottom: 1px dotted #555;
                margin: auto;
                width: 90%;
            }
        }
    }
}
</style>