<template>
	<div class="receipt">
		<div class="container">
			<div class="loading" v-if="!isContentReady">
				<loader-content :isDisplay="true"></loader-content>
			</div>

			<order-receipt-fee :orderDetail="orderDetail" v-if="isContentReady"></order-receipt-fee>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import OrderService from '@/services/orderService';
import OrderReceiptFee from '@/components/order/ReceiptFee';
import RouterPath from '@/router/path';

export default {
	components: {
		OrderReceiptFee
	},
	data() {
		return {
			isContentReady: false,
			orderDetail: null
		};
	},
	mounted() {
        this.getContent();
    },
	methods: {
		async getContent() {
            this.isContentReady = false;

			const param = {
				id: this.$route.params.id
			};
            const result = await OrderService.getOrderDetail(param);

			if (result.data) {
				this.orderDetail = result.data;
				this.isContentReady = true;
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}
        }
	},
	metaInfo() {
		const title = 'ใบเสร็จรับเงินค่าธรรมเนียม #' + this.$route.params.id;
		const desc = 'ใบเสร็จรับเงินค่าธรรมเนียม YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
.receipt {
	padding: 20px;
	margin: 0 0 20px;

	.loading {
		height: 500px;
	}
}
</style>