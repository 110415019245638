<template>
	<div class="paper">
        <div class="loading" v-if="!isContentReady">
            <loader-content :isDisplay="true"></loader-content>
        </div>

        <template v-if="isContentReady">
            <div class="operation mt-4">
                <button class="btn btn-warning" @click="downloadPDF('ReceiptFee')">
                    <b-icon icon="cloud-download" class="icon"></b-icon>
                    DOWNLOAD
                </button>
            </div>

            <div class="preview">
                <img id="paperPrint" :src="printImage" v-if="printImage" />
            </div>
			
        </template>

        <div class="print-panel">
            <div ref="printElement">
				<order-receipt-fee-print class="print-cover" :content="orderDetail"></order-receipt-fee-print>
            </div>
        </div>
    </div>
</template>

<script>
import OrderReceiptFeePrint from '@/components/order/ReceiptFeePrint';
import MixinPrintPaper from '@/components/seller/mixins/printPaper';

export default {
	mixins: [ MixinPrintPaper ],
	components: {
		OrderReceiptFeePrint
	},
	data() {
		return {
			isContentReady: false,
			deliveryCoverImage: null
		};
	},
    props: {
        orderDetail: Object
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/order/style/PaperPrint.scss';

.paper {
    background: none;
    padding: 0;
}

.operation {
    text-align: center;
    margin-bottom: 30px;

    .btn {
        padding: 7px 15px;
        font-weight: bold;
        font-size: 13px;

        .icon {
            margin-right: 5px;
        }
    }
}

.preview {
	display: block;
	margin: auto;
	max-width: 100%;
    width: 800px;
    background: #fff;
    padding: 0 20px 20px;

    img {
        display: block;
        width: 100%;
    }
}

.print-cover {
    padding-top: 40px;
}
</style>